/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import React from 'react';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import PageTitle from '@fify.at/gatsby-theme-base/src/components/PageTitle/PageTitle';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function Datenschutzerkaerung({ location, data }) {
  // console.log(data);
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Datenschutzerklärung"
      description="Der Schutz von personenbezogenen Daten ist uns wichtig und auch
        gesetzlich gefordert. Die Verarbeitung Ihrer personenbezogenen Daten
        erfolgt nach den datenschutzrechtlichen Bestimmungen."
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      {/*<HeroSectionContainer*/}
      {/*  fluid={true}*/}
      {/*  orientation="east"*/}
      {/*  variant="alpha"*/}
      {/*  title="WirKaufenDeinHaus"*/}
      {/*  subtitle="Datenschutzerklärung"*/}
      {/*  image={data.hero}*/}
      {/*/>*/}
      <Section container="small">
        <Heading title="Datenschutzerklärung" />
        <IntroContent />
      </Section>
    </HomePageLayout>
  );
}

function IntroContent() {
  // console.log(images)
  return (
    <>
      <h1>DATENSCHUTZERKL&Auml;RUNG</h1>
      <p>Stand: Oktober 2020</p>
      <p>
        Der Schutz von personenbezogenen Daten ist uns wichtig und auch
        gesetzlich gefordert. Die Verarbeitung Ihrer personenbezogenen Daten
        erfolgt nach den datenschutzrechtlichen Bestimmungen. Die nachstehende
        Übersicht soll Sie über die wichtigsten Aspekte der Verarbeitung
        personenbezogener Daten informieren.
      </p>
      <h2>Verantwortlicher</h2>
      <p>
        <strong>BUYMYHOME24 GmbH</strong>
        <br />
        <strong>Gewerblicher An- und Verkauf von Immobilien</strong>
        <br />
        Obere Landstrasse 9
        <br />
        3500 Krems, Österreich
        <br />
        Firmenbuchnr.: 535083m
        <br />
        Gerichtsstand: Wien
      </p>
      <p>
        Tel:{' '}
        <a href="tel:+436601151400" rel="noopener noreferrer" target="_blank">
          +43 660 11 51 400
        </a>
        <br />
        E-Mail:{' '}
        <a href="mailto:office@wirkaufendeinhaus.at">
          office@wirkaufendeinhaus.at
        </a>
        <br />
        Website:{' '}
        <a
          href="https://www.wirkaufendeinhaus.at/"
          rel="noopener noreferrer"
          target="_blank"
        >
          WirkaufendeinHaus.at
        </a>
      </p>
      <p>
        Die nach dem E-Commerce-Gesetz (ECG) bereitgestellten Informationen
        k&ouml;nnen direkt im{' '}
        <a
          href="https://firmen.wko.at/wkdh-gmbh/nieder%c3%b6sterreich/?firmaid=5fd168a3-fea9-4067-aea5-cdd257e31434&suchbegriff=wkdh"
          rel="noopener noreferrer"
          target="_blank"
        >
          wko.at Firmen A-Z
        </a>{' '}
        abgerufen werden.
      </p>
      <p>
        <strong>Rechtsgrundlage</strong>
      </p>
      <p>
        Vertrag, Vertragsanbahnung (Vermittlungsvertrag) sowie gesetzliche
        Grundlage.
      </p>
      <p>
        <strong>Verarbeitungsrahmen Website</strong>
      </p>
      <p>
        Im Rahmen der Webseite mit der URL www.wirkaufendeinhaus.at verarbeiten
        wir die nachfolgend im Einzelnen aufgef&uuml;hrten personenbezogenen
        Daten von Ihnen. Wir verarbeiten nur Daten von Ihnen, die Sie aktiv auf
        unserer Webseite angeben (z.B. durch das Ausf&uuml;llen von Formularen
        oder durch Ausf&uuml;llen des Immobilienrechners) oder die Sie bei der
        Nutzung unseres Angebotes automatisch zur Verf&uuml;gung stellen.
      </p>
      <p>
        Ihre Daten werden ausschlie&szlig;lich von uns verarbeitet und
        grunds&auml;tzlich nicht an Dritte verkauft, verliehen oder
        weitergegeben. Sofern wir uns bei der Verarbeitung Ihrer
        personenbezogenen Daten der Hilfe externer Dienstleister bedienen,
        erfolgt dies im Rahmen einer sogenannten Auftragsverarbeitung, bei der
        wir als Auftraggeber unserem Auftragnehmern gegen&uuml;ber
        weisungsbefugt sind. Zum Betrieb unserer Webseite setzen wir f&uuml;r
        das Hosting, sowie zur Wartung, Pflege und Weiterentwicklung externe
        Dienstleister ein. Sollten bei einzelnen aufgef&uuml;hrten
        Verarbeitungen weitere externe Dienstleister zum Einsatz kommen, werden
        sie dort benannt.
      </p>
      <p>
        Diese Daten erheben, speichern und nutzen wir nur auf Grundlage der
        Informationen, die Sie uns freiwillig oder automatisch zur
        Verf&uuml;gung stellen. Sofern es rechtlich einer Einwilligung bedarf,
        weisen wir Sie auf diesen Umstand und Ihre Widerrufsm&ouml;glichkeiten
        gesondert hin (siehe unten).
      </p>
      <p>
        Ihre personenbezogenen Daten erheben, verarbeiten und nutzen wir
        vorrangig zum Zweck der Begr&uuml;ndung und Abwicklung von
        Vertr&auml;gen (insbesondere Kaufvertr&auml;ge &uuml;ber Immobilien).
        Ihre personenbezogenen Daten erheben, verarbeiten und nutzen wir auch
        zur Anzeige personalisierter Werbung innerhalb unseres Angebotes sowie
        zur bedarfsgerechten Gestaltung und jeweiligen Verbesserung unseres
        Angebotes.
      </p>
      <p>
        Eine Daten&uuml;bermittlung in Drittstaaten findet grunds&auml;tzlich
        nicht statt und ist auch nicht geplant.&nbsp;
      </p>
      <p>
        <strong>Bestandsdaten</strong>
      </p>
      <p>
        Ihre personenbezogenen Daten, soweit diese f&uuml;r die Begr&uuml;ndung,
        inhaltliche Ausgestaltung oder &Auml;nderung eines
        Vertragsverh&auml;ltnisses erforderlich sind (Bestandsdaten), werden
        haupts&auml;chlich zur Abwicklung etwaiger zwischen uns abgeschlossener
        Vertr&auml;ge und der Erbringung unserer Dienstleistungen erhoben und
        verwendet.
      </p>
      <p>
        Die von Ihnen mitgeteilten personenbezogenen Daten (Anrede, Name,
        Anschrift, E-Mail-Adresse, Telefonnummer, Objektdaten) werden
        insbesondere f&uuml;r die &Uuml;bermittlung weiterf&uuml;hrender
        Informationen oder f&uuml;r die Beantwortung individueller Anfragen
        verwendet. Ihre personenbezogenen Daten erheben, verarbeiten und nutzen
        wir auch zur Anzeige personalisierter Werbung innerhalb unseres
        Angebotes sowie zur bedarfsgerechten Gestaltung und jeweiligen
        Verbesserung unseres Angebotes.
      </p>
      <p>Grunds&auml;tzliche Speicherdauer der Daten</p>
      <p>
        Die Daten werden w&auml;hrend der Dauer des Vertragsverh&auml;ltnisses
        und nach Beendigung dessen zumindest solange aufbewahrt, als gesetzliche
        Aufbewahrungsfristen bestehen oder Verj&auml;hrungsfristen potentieller
        Rechtsanspr&uuml;che noch nicht abgelaufen sind.
      </p>
      <h3>Die VERARBEITUNGEN IM EINZELNEN</h3>
      <h4>1. Bereitstellung der Webseite und Server-Logfiles</h4>
      <p>
        <strong>1.1 Beschreibung der Verarbeitung</strong>
      </p>
      <p>
        Bei jedem Aufruf der Webseite erfassen wir automatisch Informationen,
        die Ihr Browser an unseren Server &uuml;bermittelt. Diese werden auch in
        den sogenannten Logfiles unseres Systems gespeichert. Dabei handelt es
        sich um die folgenden Daten:
      </p>
      <p>Ihre IP-Adresse:</p>
      <ul>
        <li>
          die von Ihnen verwendete Browsersoftware, sowie deren Version und
          Sprache
        </li>
        <li>das von Ihnen verwendete Betriebssystem</li>
        <li>
          die Webseite, von der Sie auf unsere Webseite gelangt sind
          (sogenannter Referrer)
        </li>
        <li>die von Ihnen auf unserer Webseite aufgerufenen Unterseiten</li>
        <li>das Datum und die Uhrzeit Ihres Aufrufes unserer Webseite</li>
        <li>Ihr Internet-Service-Provider</li>
        <li>&Uuml;bertragene Datenmenge</li>
        <li>Land und Ort aus dem Sie unsere Webseite besucht haben</li>
        <li>Ihre Aufenthaltsdauer auf unserer Webseite</li>
      </ul>
      <p>
        Die vor&uuml;bergehende Speicherung Ihrer IP-Adresse durch das System
        ist notwendig, um unsere Webseite an das Endger&auml;t eines Nutzers
        ausliefern zu k&ouml;nnen. Hierf&uuml;r muss die IP-Adresse des Nutzers
        f&uuml;r die Dauer der Sitzung gespeichert bleiben.&nbsp;
      </p>
      <p>
        <strong>1.2 Zweck</strong>
        <span>
          <strong>&nbsp; &nbsp;&nbsp;</strong>
        </span>
      </p>
      <p>
        Die Verarbeitung erfolgt, um den Aufruf der Webseite zu
        erm&ouml;glichen, sowie deren Stabilit&auml;t und Sicherheit zu
        gew&auml;hrleisten. Dar&uuml;ber hinaus dient die Verarbeitung der
        statistischen Auswertung und Verbesserung unseres Online-Angebotes.
      </p>
      <h4>2. Kontaktaufnahmeformulare</h4>
      <p>
        <strong>2.1 Beschreibung der Verarbeitung</strong>
      </p>
      <p>
        F&uuml;r die Wahrnehmung einzelner Angebote und Dienstleistungen unseres
        Unternehmens haben wir auf unserer Webseite Formulare bereitgestellt,
        die Sie dort ausf&uuml;llen und elektronisch an uns &uuml;bermitteln
        k&ouml;nnen. In diesem Formular werden Sie gebeten, die Formularfelder
        auszuf&uuml;llen. Die Pflichtfelder sind mit einem &bdquo;*&ldquo;
        markiert. Alle &uuml;brigen Angaben sind freiwillig. Wenn Sie den Button
        unterhalb des Formulars bet&auml;tigen, werden die Daten an uns
        &uuml;bertragen. Sie willigen dadurch ein, Ihre Daten zur
        Kontaktaufnahme per Telefon oder Email nutzen zu d&uuml;rfen. Auf
        Grundlage Ihrer Einwilligung werden wir uns mit Ihnen telefonisch oder
        per E-Mail in Verbindung setzen.
      </p>
      <p>
        <strong>2.2 Zweck</strong>
      </p>
      <p>
        Mit der Bereitstellung eines Kontaktformulars auf unserer Webseite
        wollen wir Ihnen eine bequeme M&ouml;glichkeit anbieten, sich mit uns in
        Verbindung zu setzen. Die mit und in dem Kontaktformular bzw. Ihrer
        E-Mail oder einem Telefonat &uuml;bermittelten Daten werden &nbsp;zum
        Zwecke der Bearbeitung und Beantwortung Ihres Anliegens verwendet.
      </p>
      <p>
        <strong>2.3 Empf&auml;nger</strong>
      </p>
      <p>
        Wir werden Ihre personenbezogenen Daten, die Sie im Rahmen der
        Kontaktanfrageformulare an uns &uuml;bermitteln nur an Dritte
        weitergeben, sofern dies f&uuml;r die Erbringung unserer
        Dienstleistungen oder f&uuml;r einen Vertragsschluss und dessen
        Abwicklung erforderlich ist, wir rechtlich dazu verpflichtet sind oder
        Sie in die Datenweitergabe eingewilligt haben. Insbesondere findet eine
        Weitergabe Ihrer Daten an ein Partnerunternehmen von BUYMYHOME24 GmbH
        oder ein anderes Unternehmen nur auf Ihren Wunsch und mit Ihrer
        Einwilligung statt.&nbsp;
      </p>
      <p>
        M&ouml;gliche Empf&auml;nger k&ouml;nnen sein: Abteilungen des
        Unternehmens, die mit der Gesch&auml;ftsabwicklung befasst sind (z.B.
        EDV, sonstige Verwaltungseinheiten) oder Gesellschaften der
        Unternehmensgruppe (z.B. zur Abwicklung gemeinsamer Projekte), an der
        Gesch&auml;ftsabwicklung beteiligte Dritte (an der
        Gesch&auml;ftsabwicklung notwendigerweise teilnehmende Personen und
        potentielle Vertragspartner, weitere Makler, Vermittlungsplattformen,
        Hausverwaltungen, Finanzierungsunternehmen, private und &ouml;ffentliche
        Stellen, die Informationen zu Objekten bekannt geben k&ouml;nnen oder
        ben&ouml;tigen, Versicherungen), Dienstleister des Verantwortlichen
        (z.B. Steuerberater, Lohnverrechnung, Rechtsanwalt) sowie Beh&ouml;rden
        (Sozialversicherung, Finanzamt, sonstige Beh&ouml;rden), Rechtsvertreter
        (bei der Durchsetzung von Rechten oder Abwehr von Anspr&uuml;chen oder
        im Rahmen von Beh&ouml;rdenverfahren) oder Unternehmen, die im Rahmen
        der Betreuung der IT-Infrastruktur (Software, Hardware) als
        Auftragnehmer t&auml;tig sind.
      </p>
      <p>
        Wir k&ouml;nnen des Weiteren aufgrund gesetzlicher Bestimmungen und/oder
        beh&ouml;rdlicher oder gerichtlicher Anordnungen zu einer
        Datenweitergabe berechtigt oder verpflichtet sein. Dabei kann es sich
        insbesondere um die Auskunftserteilung f&uuml;r Zwecke der
        Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung geistiger
        Eigentumsrechte handeln.
      </p>
      <h4>3. Immobilienbewertung 3.1 Beschreibung der Verarbeitung</h4>
      <p>
        Ein Teil unseres Angebotes besteht darin, kostenlose
        Immobilienbewertungen durchzuf&uuml;hren. Mit dem Ausf&uuml;llen und
        Absenden des Bewertungsformulars (z.B.
        https://www.wirkaufendeinhaus.at/online-immobilienbewertung/) nehmen Sie
        dieses Angebot an und beauftragen BUYMYHOME24 GmbH, eine kostenlose
        Bewertung Ihrer Immobilie vorzunehmen. Zur detaillierten Bewertung der
        Immobilie bedingt in der Regel die Inaugenscheinnahme der jeweiligen zum
        Verkauf stehenden Immobilie durch einen Sachkundigen vor Ort.
        BUYMYHOME24 GmbH bedient sich zur Erf&uuml;llung des erteilten Auftrages
        und aus Qualit&auml;tsgr&uuml;nden regionaler Immobilienexperten, die
        vom WKDH zertifiziert wurden und in direkter Partnerschaft mit unserem
        Unternehmen stehen. Durch Ihre Einwilligung auf unserem Angebot
        beauftragen Sie BUYMYHOME24 GmbH lediglich mit der Durchf&uuml;hrung
        einer kostenlosen Bewertung Ihrer Immobilie, sowie damit im Zusammenhang
        stehender Dienstleistungen. Sie versichern diesbez&uuml;glich auf
        eigenen Namen und Rechnung zu handeln. F&uuml;r die Durchf&uuml;hrung
        der Bewertung und die dazugeh&ouml;rende Beratung ist es erforderlich,
        Ihre pers&ouml;nlichen Angaben an angeschlossene Immobilienpartner
        weiterzuleiten, um danach die entsprechenden R&uuml;ckmeldungen zu
        empfangen und ggf. an Sie weiterleiten zu d&uuml;rfen.&nbsp;
      </p>
      <p>
        Sie bevollm&auml;chtigen hiermit BUYMYHOME24 GmbH, Ihre Daten sowie die
        f&uuml;r die kostenlose Immobilienbewertung notwendigen Unterlagen/Daten
        an die externen WKDH-Immobilienpartner (z.B. Donau-Immobilien GmbH &amp;
        Co KG) weiterzugeben, diese mit der Kontaktaufnahme zu beauftragen, von
        diesen Angebote einzuholen und s&auml;mtliche mit dem Auftrag
        zusammenh&auml;ngenden Schriftverkehre entgegenzunehmen. Sie
        bevollm&auml;chtigen BUYMYHOME24 GmbH insbesondere, Informationen
        &uuml;ber Bewertungen, Vertragsangebote und Verkaufsaufgaben der
        verbundenen Immobilienpartner zur Weiterleitung an Sie entgegenzunehmen.
        Sie willigen ein, dass die Immobilienpartner Angaben &uuml;ber den
        Status der Bewertung und ggf. aus der Bewertung resultierende
        weitergehende Auftr&auml;ge an BUYMYHOME24 GmbH &uuml;bermitteln
        d&uuml;rfen.
      </p>
      <p>
        WirkaufendeinHaus.at nutzt diese Informationen im Rahmen der
        individuellen Beratung der konkreten Bewertungsanfrage und zur
        kompetenten Unterst&uuml;tzung im Rahmen des Auftrages.
      </p>
      <p>
        <strong>3.2 Zweck</strong>
      </p>
      <p>
        Die Verarbeitung erfolgt zur Aufnahme und Bearbeitung von Anfragen zur
        Durchf&uuml;hrung von kostenlosen Immobilienbewertungen &uuml;ber unsere
        Webseite sowie zur Sicherstellung der Qualit&auml;t.
      </p>
      <p>
        <strong>3.3 Empf&auml;nger</strong>
      </p>
      <p>
        Wir werden Ihre personenbezogenen Daten, die Sie im Rahmen des
        Anfrageformulars an uns &uuml;bermitteln nur an Immobilienpartner
        weitergeben, wenn dies zur Durchf&uuml;hrung Ihrer Anfrage erforderlich
        ist.&nbsp;
      </p>
      <h4>4. Cookies</h4>
      <p>
        <strong>4.1 Beschreibung der Verarbeitung</strong>
      </p>
      <p>
        Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um kleine
        Textdateien, die beim Besuch einer Webseite auf dem Endger&auml;t des
        Nutzers gespeichert werden. Cookies enthalten Informationen, die die
        Wiedererkennung eines Endger&auml;tes und ggf. bestimmte Funktionen
        einer Webseite erm&ouml;glichen. Zumeist setzen wir lediglich so
        genannte &bdquo;Session-Cookies&ldquo; ein. Diese werden automatisch
        gel&ouml;scht, wenn Sie Ihre Internetsitzung beenden und den Browser
        schlie&szlig;en. Andere Cookies bleiben f&uuml;r einen l&auml;ngeren
        Zeitraum auf Ihrem Endger&auml;t gespeichert und erm&ouml;glichen
        Partnerunternehmen, Ihren Browser bzw. Rechner wiederzuerkennen
        (persistente Cookies). Je nach Cookie werden im Fall von persistenten
        Cookies diese automatisch je nach vorgegebener Speicherdauer
        gel&ouml;scht.
      </p>
      <p>
        <strong>4.2 Zweck</strong>
      </p>
      <p>
        Wir setzen Cookies ein, um unsere Webseite nutzerfreundlicher zu
        gestalten und die in Ziffer 6.1 beschrieben Funktionen anzubieten. Wir
        arbeiten u. a. mit Dienstleistern zur Webseitenanalyse und mit
        Werbepartnern zusammen, die uns dabei unterst&uuml;tzen, unsere Webseite
        f&uuml;r Sie interessant und nutzerfreundlich zu gestalten. Zu diesem
        Zweck sind unter Umst&auml;nden auf unserer Website auch Cookies dieser
        Drittanbieter auf Ihrer Festplatte gespeichert. Sollten wir Dritten
        gestatten derartige Cookies einzusetzen, informieren wir Sie in den
        nachfolgenden Abschnitten &uuml;ber die auf diese Weise erhobenen
        Informationen.
      </p>
      <p>
        <strong>4.3 Speicherdauer</strong>
      </p>
      <p>
        Cookies werden automatisch mit dem Ende einer Sitzung bzw. mit Ablauf
        der angegebenen Speicherdauer gel&ouml;scht. Da Cookies auf Ihrem
        Endger&auml;t gespeichert werden, haben Sie als Nutzer auch die volle
        Kontrolle &uuml;ber die Verwendung von Cookies. Durch eine &Auml;nderung
        der Einstellungen in Ihrem Internetbrowser k&ouml;nnen Sie die
        &Uuml;bertragung von Cookies deaktivieren oder einschr&auml;nken.
      </p>
      <p>
        Nachfolgend haben wir f&uuml;r Sie die Links zusammengestellt, die Sie
        zu Anleitungen f&uuml;hren, wie Sie die Einstellungen bei den
        g&auml;ngigen Browsern ver&auml;ndern k&ouml;nnen. Weitere Informationen
        erhalten sie im Support Menu Ihres Browsers:
      </p>
      <ul>
        <li>
          Internet Explorer:{' '}
          <a
            href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </li>
        <li>
          Firefox:{' '}
          <a
            href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          </a>
        </li>
        <li>
          Chrome:{' '}
          <a href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en">
            https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en
          </a>
        </li>
        <li>
          Safari:{' '}
          <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac">
            https://support.apple.com/de-at/guide/safari/sfri11471/mac
          </a>
        </li>
        <li>
          Opera:{' '}
          <a href="https://help.opera.com/de/latest/web-preferences/">
            https://help.opera.com/de/latest/web-preferences/
          </a>
        </li>
      </ul>
      <p>
        Bereits gespeicherte Cookies k&ouml;nnen jederzeit gel&ouml;scht werden.
        Dies kann auch automatisiert erfolgen. Werden Cookies f&uuml;r unsere
        Webseite deaktiviert, k&ouml;nnen einzelne Funktionen unserer Webseite
        nicht oder nur eingeschr&auml;nkt genutzt werden.
      </p>
      <h4>5. Soziale Netzwerke</h4>
      <p>
        Unsere Webseite verwendet keinerlei sogenannter Social Media Plugins.
        Die auf unserer Webseite angezeigten Logos der Sozialen Netzwerke
        Facebook und Instagram sowie Google sind lediglich mit den
        entsprechenden Profilen unseres Unternehmens verlinkt. Wenn Sie eines
        der Logos anklicken, werden Sie auf die externe Webseite des jeweiligen
        Sozialen Netzwerks weitergeleitet.
      </p>
      <h4>6. Google Analytics</h4>
      <p>
        <strong>6.1 Beschreibung der Verarbeitung</strong>
      </p>
      <p>
        Unsere Webseite verwendet &bdquo;Google Analytics&ldquo;, einen
        Webanalysedienst der Googel Ireland Limited, Gordon House, Barrow
        Street, Dublin 4. Ireland (nachfolgend &bdquo;Google&ldquo; genannt).
        Google Analytics setzt Cookies ein (siehe Ziffer 5), die eine Analyse
        Ihrer Nutzung unseres Angebotes erm&ouml;glichen. Wir verwenden Google
        Analytics in der angebotenen Version &bdquo;Universal Analytics&ldquo;,
        die diese Analyse ger&auml;te&uuml;bergreifend durch Zuordnung der Daten
        zu einer pseudonymen User-ID erlaubt. Die durch den Cookie erzeugten
        Informationen werden in der Regel an einen Server von Google in den USA
        &uuml;bertragen und dort gespeichert. Wir verwenden Google Analytics
        jedoch ausschlie&szlig;lich mit einer IP-Anonymisierung. Dadurch wird
        Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens
        &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur
        in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von
        Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Die im Rahmen
        von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird
        nicht mit anderen Daten von Google zusammengef&uuml;hrt. Die von Google
        Analytics erstellten Statistiken erfassen insbesondere wie viele Nutzer
        unsere Website besuchen, aus welchem Land bzw. Ort der Zugriff erfolgt,
        welche Unterseiten aufgerufen werden und &uuml;ber welche Links oder
        Suchbegriffe Besucher auf unsere Webseite gelangen. Die
        Nutzerbedingungen von Google Analytics finden Sie unter{' '}
        <a
          href="http://www.google.com/analytics/terms/de.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          http://www.google.com/analytics/terms/de.html
        </a>
        . Eine &Uuml;bersicht zum Datenschutz bei Google Analytics ist unter{' '}
        <a
          href="http://www.google.com/intl/de/analytics/learn/privacy.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          http://www.google.com/intl/de/analytics/learn/privacy.html
        </a>{' '}
        abrufbar. Die Datenschutzerkl&auml;rung von Google l&auml;sst sich unter{' '}
        <a
          href="http://www.google.de/intl/de/policies/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          http://www.google.de/intl/de/policies/privacy
        </a>{' '}
        einsehen.
      </p>
      <p>
        <strong>6.2 Zweck</strong>
      </p>
      <p>
        Die Verarbeitung erfolgt, um die Nutzung unserer Webseite auswerten zu
        k&ouml;nnen. Die dadurch gewonnenen Informationen dienen der
        Verbesserung und bedarfsgerechten Gestaltung unseres Online-Auftritts.
      </p>
      <p>
        <strong>6.3 Speicherdauer und Widerspruchsrecht</strong>
      </p>
      <p>
        Die Speicherdauer, sowie Ihre Kontroll- und
        Einstellungsm&ouml;glichkeiten bei Cookies haben wir in Ziffer 6
        erl&auml;utert. Sie k&ouml;nnen der Datenverarbeitung durch Google
        Analytics jederzeit widersprechen, indem Sie das von Google unter
        https://tools.google.com/dlpage/gaoptout?hl=de angebotene Browser-Add-on
        herunterladen und installieren. Alternativ haben Sie die
        M&ouml;glichkeit, auf den nachfolgenden Link zu klicken. Dadurch wird
        ein Opt-Out-Cookie auf Ihrem Endger&auml;t gesetzt, der die Erfassung
        Ihrer Daten bei zuk&uuml;nftigen Besuchen dieser Webseite unterbindet.
        Die mit Google Analytics verarbeiteten und gespeicherten Analysedaten
        werden von uns nach 50 Monaten automatisch gel&ouml;scht.
      </p>
      <p>
        <strong>
          6.4 Empf&auml;nger und &Uuml;bermittlung in Drittstaaten
        </strong>
      </p>
      <p>
        Google Analytics ist f&uuml;r uns im Rahmen einer Auftragsverarbeitung
        als Dienstleister t&auml;tig. Google verarbeitet Ihre personenbezogenen
        Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen.
        Weitere Informationen zum EU-US-Privacy-Shield finden Sie unter{' '}
        <a
          href="https://www.privacyshield.gov/EU-US-Framework"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/EU-US-Framework
        </a>
        .
      </p>
      <h4>7. Facebook Pixel</h4>
      <p>
        <strong>7.1 Beschreibung der Verarbeitung</strong>
      </p>
      <p>
        Unsere Webseite verwendet die Remarketing-Dienste &bdquo;Facebook Pixel
        der von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA
        betrieben wird (&bdquo;Facebook&ldquo;). &Uuml;ber das &bdquo;Facebook
        Pixel&ldquo; ist es uns m&ouml;glich, auf dem sozialen Netzwerk
        Werbeanzeigen zu schalten, die sich zielgenau an diejenigen
        Facebook-Nutzer wenden, die Interesse an unserem Angebot gezeigt haben
        &ndash; z.B. durch einen fr&uuml;heren Besuch auf unserer Webseite. Mit
        Hilfe des &bdquo;Facebook Pixels&ldquo; k&ouml;nnen wir dar&uuml;ber
        hinaus die Wirksamkeit und die Reichweite unserer Werbung auf Facebook
        nachvollziehen und auswerten, indem erfasst wird, ob Facebook-Nutzer mit
        unseren Werbeanzeigen auf dem sozialen Netzwerk interagieren durch das
        Anklicken der Werbeanzeigen auf unsere Webseite weitergeleitet werden.
        Wenn Sie unsere Webseite besuchen wird daher eine Verbindung zu den
        Facebook-Servern hergestellt und das &bdquo;Facebook-Pixel&ldquo; in
        unsere Webseite eingebettet. Zus&auml;tzlich kann es sein, dass Facebook
        auf Ihrem Endger&auml;t ein Cookie (siehe oben Ziffer 6) speichert. Wenn
        Sie bei Facebook eingeloggt sind oder sich sp&auml;ter bei Facebook
        einloggen, wird Ihr Besuch auf unserer Webseite Ihrem Benutzerkonto
        zugeordnet. Die &uuml;ber Sie mittels des &bdquo;Facebook Pixels&ldquo;
        erhobenen Daten sind f&uuml;r uns anonym. Sie liefern uns keinerlei
        R&uuml;ckschl&uuml;sse auf Ihre Person. Jedoch ist seitens Facebook eine
        Verbindung zu Ihrem Nutzerprofil m&ouml;glich. Die Datenverarbeitung
        durch Facebook erfolgt gem&auml;&szlig; der Datenrichtlinie des
        Unternehmens, die unter{' '}
        <a
          href="https://www.facebook.com/policy.php"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/policy.php
        </a>{' '}
        abgerufen werden kann.
      </p>
      <p>
        <strong>7.2 Zweck</strong>
      </p>
      <p>
        Die Verarbeitung erfolgt, um zielgenaue Online-Werbung f&uuml;r unsere
        eigenen Angebote durchf&uuml;hren und deren Wirksamkeit und Reichweite
        auswerten zu k&ouml;nnen.
      </p>
      <p>
        <strong>7.3 Speicherdauer und Widerspruchsrecht</strong>
      </p>
      <p>
        Die Speicherdauer, sowie Ihre Kontroll- und
        Einstellungsm&ouml;glichkeiten bei Cookies haben wir in Ziffer 4
        erl&auml;utert. Sie k&ouml;nnen der Datenerhebung durch das
        &bdquo;Facebook Pixel&ldquo; und der Verwendung Ihrer Daten zur
        Darstellung von Facebook-Werbeanzeigen jederzeit widersprechen.
      </p>
      <p>
        <strong>
          7.4 Empf&auml;nger und &Uuml;bermittlung in Drittstaaten
        </strong>
      </p>
      <p>
        Durch die Einbindung des &bdquo;Facebook Pixels&ldquo; werden ggf.
        personenbezogenen Daten an Facebook &uuml;bermittelt. Facebook
        verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich
        dem EU-US-Privacy-Shield unterworfen. Weitere Informationen zum
        EU-US-Privacy-Shield finden Sie unter{' '}
        <a
          href="https://www.privacyshield.gov/EU-US-Framework"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.privacyshield.gov/EU-US-Framework
        </a>
        .
      </p>
      <h4>8. Sicherheitsma&szlig;nahmen</h4>
      <p>
        Um Ihre pers&ouml;nlichen Daten vor fremdem Zugriff zu sch&uuml;tzen,
        haben wir unsere Webseite mit einem SSL- bzw. TLS-Zertifikat versehen.
        SSL steht f&uuml;r &bdquo;Secure-Sockets-Layer&ldquo; und TLS f&uuml;r
        &bdquo;Transport Layer Security&ldquo; und verschl&uuml;sselt die
        Kommunikation von Daten zwischen einer Webseite und dem Endger&auml;t
        des Nutzers. Sie erkennen die aktive SSL- bzw. TLS-Verschl&uuml;sselung
        an einem kleinen Schloss-Logo, das ganz links in der Adresszeile des
        Browsers angezeigt wird.
      </p>
      <p>Ihre Rechte</p>
      <p>Betroffenenrechte</p>
      <p>
        Im Hinblick auf die oben beschriebene Datenverarbeitung durch unser
        Unternehmen stehen Ihnen die folgenden Betroffenenrechte zu:
      </p>
      <p>Auskunft (Art. 15 DSGVO)</p>
      <p>
        Sie haben das Recht, von uns eine Best&auml;tigung dar&uuml;ber zu
        verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten.
        Ist dies der Fall, steht Ihnen unter den in Art. 15 DSGVO genannten
        Voraussetzungen ein Recht auf Auskunft &uuml;ber diese personenbezogenen
        Daten und auf die in Art. 15 DSGVO aufgef&uuml;hrten weiteren
        Informationen zu.
      </p>
      <p>Berichtigung (Art. 16 DSGVO)</p>
      <p>
        Sie haben das Recht, von uns unverz&uuml;glich die Berichtigung Sie
        betreffender unrichtiger personenbezogener Daten und ggf. die
        Vervollst&auml;ndigung unvollst&auml;ndiger personenbezogener Daten zu
        verlangen.
      </p>
      <p>L&ouml;schung (Art. 17 DSGVO)</p>
      <p>
        Sie haben das Recht, von uns zu verlangen, dass Sie betreffende
        personenbezogene Daten unverz&uuml;glich gel&ouml;scht werden, sofern
        einer der in Art. 17 DSGVO im einzelnen aufgef&uuml;hrten Gr&uuml;nde
        zutrifft, z. B. wenn Ihre Daten f&uuml;r die von uns verfolgten Zwecke
        nicht mehr ben&ouml;tigt werden
      </p>
      <p>Einschr&auml;nkung der Datenverarbeitung (Art. 18 DSGVO)</p>
      <p>
        Sie haben das Recht, von uns die Einschr&auml;nkung der Verarbeitung zu
        verlangen, wenn eine der in Art. 18 DSGVO aufgef&uuml;hrten
        Voraussetzungen gegeben ist, z. B. wenn Sie die Richtigkeit Ihrer
        personenbezogenen Daten bestreiten, wird die Datenverarbeitung f&uuml;r
        die Dauer eingeschr&auml;nkt, die uns die &Uuml;berpr&uuml;fung der
        Richtigkeit Ihrer Daten erm&ouml;glicht.
      </p>
      <p>Daten&uuml;bertragbarkeit (Art. 20 DSGVO)</p>
      <p>
        Sie haben das Recht, unter den in Art. 20 DSGVO aufgef&uuml;hrten
        Voraussetzungen, die Herausgabe der Sie betreffenden Daten in einem
        strukturierten, g&auml;ngigen und maschinenlesbaren Format zu verlangen.
      </p>
      <p>Widerruf von Einwilligungen (Art. 7 Abs. 3 DSGVO)</p>
      <p>
        Sie haben das Recht, bei einer Verarbeitung, die auf einer Einwilligung
        beruht, Ihre Einwilligung jederzeit zu widerrufen. Der Widerruf gilt ab
        dem Zeitpunkt seiner Geltendmachung. Er wirkt mit anderen Worten
        f&uuml;r die Zukunft. Die Verarbeitung wird durch den Widerruf der
        Einwilligung also nicht r&uuml;ckwirkend rechtswidrig.
      </p>
      <p>Beschwerde (Art. 77 DSGVO)</p>
      <p>
        Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
        personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie
        das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde. Sie
        k&ouml;nnen dieses Recht bei einer Aufsichtsbeh&ouml;rde in dem
        EU-Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
        Orts des mutma&szlig;lichen Versto&szlig;es geltend machen.
      </p>
      <p>Verbot automatisierter Entscheidungen/ Profiling (Art. 22 DSGVO)</p>
      <p>
        Entscheidungen, die f&uuml;r Sie rechtliche Folge nach sich ziehen oder
        Sie erheblich beeintr&auml;chtigen, d&uuml;rfen nicht
        ausschlie&szlig;lich auf eine automatisierte Verarbeitung
        personenbezogener Daten &ndash; einschlie&szlig;lich eines Profiling
        &ndash; gest&uuml;tzt werden. Wir teilen Ihnen mit, dass wir im Hinblick
        auf Ihre personenbezogenen Daten keine automatisierte
        Entscheidungsfindung einschlie&szlig;lich Profiling einsetzen.
      </p>
      <p>Widerspruch (Art. 21 DSGVO)</p>
      <p>
        Wenn wir personenbezogene Daten von Ihnen auf Grundlage von Art. 6 Abs.
        1 lit. f DSGVO (zur Wahrung &uuml;berwiegender berechtigten Interessen)
        verarbeiten, haben Sie das Recht, unter den in Art. 21 DSGVO
        aufgef&uuml;hrten Voraussetzungen dagegen Widerspruch einzulegen. Dies
        gilt jedoch nur, soweit Gr&uuml;nde vorliegen, die sich aus Ihrer
        besonderen Situation ergeben. Nach einem Widerspruch verarbeiten wir
        Ihre personenbezogenen Daten nicht mehr, es sei denn wir k&ouml;nnen
        zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
        nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen.
        Wir m&uuml;ssen die Verarbeitung ebenfalls nicht einstellen, wenn sie
        der Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen dient. In jedem Fall &ndash; auch unabh&auml;ngig
        von einer besonderen Situation &ndash; haben Sie das Recht, jederzeit
        Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
        f&uuml;r Direktwerbung einzulegen.
      </p>
      <p>
        Die Angabe der Daten ist erforderlich, damit das Vertragsverh&auml;ltnis
        ordnungsgem&auml;&szlig; durchgef&uuml;hrt werden kann. Als betroffener
        Person steht Ihnen grunds&auml;tzlich das Recht auf Auskunft,
        Berichtigung, L&ouml;schung, Einschr&auml;nkung, Widerspruch und
        Daten&uuml;bertragbarkeit im Rahmen der gesetzlichen Bestimmungen zu.
        Zur Aus&uuml;bung Ihrer Rechte wenden Sie sich bitte schriftlich an:
      </p>
      <p>BUYMYHOME24 Gmbh Obere Landstra&szlig;e 9 3500 Krems</p>
      <p>oder an:</p>
      <p>
        <a href="mailto:office@wirkaufendeinhaus.at">
          office@wirkaufendeinhaus.at
        </a>
      </p>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen
        Anspr&uuml;che sonst in irgendeiner Weise verletzt worden sind, steht
        Ihnen f&uuml;r eventuelle Beschwerden die zust&auml;ndige
        Datenschutzbeh&ouml;rde jederzeit zur Verf&uuml;gung.&nbsp;
      </p>
      <p>
        <br />
      </p>

      <Button
        variant="primary"
        data-message="Google Analytics wurde deaktiviert."
        className="ga-optout"
        href="javascript:gaOptout();"
      >
        Google Analytics deaktivieren
      </Button>
    </>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-kontakt" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
